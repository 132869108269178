import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'amfe-flexible'

import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber)

import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper)

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: 'zh',
  messages: {
    'zh': require('./lang/zh'),
    'en': require('./lang/en')
  }
})

import './assets/font/index.css' //全局使用
import './assets/font/font-size.css' //全局使用
import './assets/icon/iconfont.css'
import axios from 'axios'
Vue.prototype.$axios = axios
axios.defaults.baseURL = 'http://106.14.78.217:3001/'
// axios.defaults.baseURL = 'http://127.0.0.1:3001/'

Vue.prototype.$getView = function () {
  return {
    width:
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth, //兼容性获取屏幕宽度
    height:
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight, //兼容性获取屏幕高度
  };
};


import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
