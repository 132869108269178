<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  mounted() {
    window.addEventListener(
      "hashchange",
      () => {
        var currentPath = window.location.hash.slice(1); // 获取输入的路由
        if (this.$router.path !== currentPath) {
          console.log(this.$router.path);
          this.$router.push(currentPath); // 动态跳转
        }
      },
      false
    );
  },
};
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
}
</style>