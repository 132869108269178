module.exports = {
  Home: 'Home',
  Latest: 'Latest',
  About: 'About',
  Brand: 'Brand',
  Project: 'Project',
  Client: 'Client',
  Contact: 'Contact',
  Latest_Project: 'Latest Project',
  SoldOut: 'SoldOut',
  LogTalk: 'LogTalk',
  iTall: 'iTall',
  PharmaX: 'PharmaX',
  Insight: 'Insight',
  iMFG: 'iMFG',
  LearnMore: "LearnMore",
  soldout_text: "Under the drastic changes of stock competition and economic downturn, how can retail channels incubate brands and make a strong breakthrough to respond to the increasingly complex and diversified consumer demands?Sold Out is the upgraded brand of SmartRetail Summit, which has been precipitated for six years, and has been working with more than 150 retailers and brands in China to capture new changes in retail scenarios, and to put themselves in the new consumer situation with a strong stance.",
  logtalk_text: "Retail Logistics and Supply Chain Summit brings together over 200 domestic and international retailers, brands, and other enterprise decision-making teams and representatives. Focusing on enhancing quality and efficiency, to explore how Chinese retail enterprises can achieve digital empowerment in the value chain, product strength, and competitiveness.",
  itall1_text: 'The rapid advancement of the Fourth Industrial Revolution, characterized by big data, cloud computing, and artificial intelligence, has created an urgent need for the development of enterprise digital intelligence technology. China Tech Innovation Annual Summit with over 200 leading enterprises and science and innovation talents to address the challenges of digital transformation and explore strategies for fostering sustainable long-term development within enterprises.',
  itall2_text: "",
  pharmax1_text: "The PharmaX Summit Series aimsto provide a platform for over 200 upstream and downstream enterprises in the pharmaceutical industry to exchange insights and drive innovation. Esteemed experts in pharmaceutical research, development, operations, and investment will be invited to share their perspectives on trends, technologies, and best practices with participants.",
  pharmax2_text: '',
  insight_text: 'Insight Summit series collaborates with banking, securities, funds, insurance, and other institutions to explore and promote multi-level innovation practices in financial infrastructure, financial business models, and organizational internal structures, fully unleashing the value of data elements to create an enhanced service experience for customers.',
  imfg_text: "Intelligent Manufacturing Special Session collaborates with numerous manufacturers to analyze all aspects and connections of production involvement, focusing on manufacturing deficiencies and promoting the integration and innovation of new technology and manufacturing.",
  CHO_text: "With the rapid growth of new ecosystems and models, there is an increasing demand for digital talents. The CHO Digital Talent Summit series aims to bring together elite talents from multiple industries to expand the pool of high-level digital talent, enhance the quality of digital talent, and adapt to structural changes in talent demand.",
  about_us: 'About Us',
  about_us1_text: "As a creative business event management company, HY Exhibition is dedicated to providing event management services for outstanding enterprises, government agencies, non-governmental and non-profit organizations in China and the Asia-Pacific market. HY Exhibition's service purpose is centered around creative connections and the realization of business globalization. We aim to assist customers from various industries in refreshing their industry knowledge, addressing industry pain points, accurately identifying business opportunities, and becoming active participants in industry activities based in East China while extending our reach nationwide and globally.",
  about_us2_text: 'The core team of HY Exhibition consists of professionals from renowned exhibition and consulting enterprises both domestically and internationally. With years of experience in large-scale events, we possess keen insight, continuous creativity, efficient execution, excellent coordination skills, dedication to collaboration with clients, as well as systematic professional knowledge that enables us to be a reliable professional team throughout the entire process from strategy development and planning to delivery and review.',
  Core_business: 'Core business',
  Core_business_text: "We believe that reviewing the process from concept to implementation from the customer's perspective, and then adding a variety of creativity, is the only magic weapon for the team to provide customers with high-quality and efficient services. Through a systematic approach, we realize connection driven business and highlight new commercial value. At present, our main businesses cover the following:",
  business1: 'Summit Forum',
  business2: 'Salon Training',
  business3: 'investigation and research',
  business4: 'Activity Undertaking',
  business1_text: 'Through international conferences, summits, forums and professional exhibitions, we can help and achieve efficient and accurate business expansion, and achieve new business value.',
  business2_text: 'Establish a business social networking platform oriented to the whole industry, whole industry chain and whole process, and help enterprise clients research, plan, operate, execute and maintain high-level events.',
  business3_text: "Explore industry expers to improve the work performance and industry insight of enterprise's employees by means of seminars and training sessions.",
  business4_text: 'Customized research and marketing solutions, refined collection of core feedback and coverage marketing promotion with powerful industry resources.',
  Brand_Events: 'Brand Events',
  Project_Review: "Project_Review",
  Project_Review_text: "Huiyu Exhibition has insight into business change and enterprise innovation, and explores the demand for technology and business growth in various industries. Plan and successfully hold a series of brand activities with a variety of themes to strengthen the industry's speed Linkage has become an important traction for multi-field cooperation. Huiyu closely follows the new trends in the market and aims to play an important role as a platform for business connections. Focus on the pace of industry policies, gather the consensus of enterprise talents,Promoting multi-party cooperation in the market and realizing the effective implementation of exploration and innovation will be the substantive benchmark we have always adhered to.",
  proj1: 'CHO Digital Talent Summit',
  proj1_place: 'Shanghai',
  proj2: 'INSight',
  proj2_place: 'Beijing',
  proj3: 'LOGTALK',
  proj3_place: 'Shanghai',
  proj4: 'INSight NSight Insurtech Summit',
  proj4_place: 'Shanghai',
  proj5: 'INSight FinTech Summit',
  proj5_place: 'Shanghai',
  proj6: 'LOGTALK Retail Logistics & Supply Chain Summit',
  proj6_place: 'Beijing',
  more_proj: 'More exciting project reviews',
  our_clients: 'Our customers',
  latest_projects: 'Latest projects',
  latest1: 'Soldout, Retail, 7.20-21, Shanghai',
  latest2: 'CHO, FinTech, 6.26, BEIJING',
  latest3: 'INSight, Fintech, 9.27. BEIJING',
  latest4: 'PharmaX, Pharmaceutical, 3.16, Shanghai',
  latest5: 'CHO, Pharm, 6.27 SHANGHAI',
  latest6: 'iTall, Sci-Tech Annual Summit, 11.23, Shanghai',
  Company_Introduction: 'Company Introduction',
  events1: 'Soldout',
  events2: 'CCSS',
  events3: 'iTall',
  events4: 'INSight',
  events5: 'CHO',
  events6: 'GREEN ENERGRY',
  events7: 'LOGTALK',
  events8: 'DIgitalEdu',
  events9: 'PharmaX',
  events10: 'MTECH',
  events11: 'RE Connection',
  events12: 'OIPOs',
  events13: 'NCB',
  events14: 'GREEN ENERGRY',
  events15: 'iMFG',
  contact_us: 'Contact us',
  addr1: '6F Guosheng SOHO 1,388',
  addr2: 'Shanghai P.R.C. 200062.',
  "敬请期待": "Stay tuned",
  CHO: 'Human',
  "请认真填写此表，提交后会有专人与您联系":"Please fill out this form carefully and submit it. After submission, a professional will contact you.",
  "活动日历":"Event Calendar",
  "LOGTALK零售物流与供应链峰会":"LOGTALK Retail Logistics & Supply Chain Summit",
  "INsight保险洞察力峰会":"INSight Insurance Insight Summit",
  "CHO数字人才驱动力峰会":"CHO Digital Talent Driving Force Summit",
  "金融新质人才力峰会":"Financial New Quality Talent Summit",
  "PharmaX未来医药峰会":"PharmaX Future Pharmaceutical Summit",
  "2024.3.15|上海":"2024.3.15|Shanghai",
  "2024.3.21|北京":"2024.3.21|Beijing",
  "2024.4.25|上海":"2024.4.25|Shanghai",
  "2024.6.26|北京":"2024.6.26|Beijing",
  "2024.6.27|上海":"2024.6.27|Shanghai",
  "零售":"RETAIL",
  "营销":"MARKETING",
  "快消":"FMCG",
  "地产":"REAL ESTATE",
  "物流与供应链":"LOGISTICS & SUPPLY CHAIN",
  "金融与保险科技":"FINANCE",
  "数字货币":"DIGICCY",
  "人力资源":'HR',
  "科创":"INNOVATION",
  "网络安全":" NETWORK SECURITY",
  "制造":'MANUFACTING',
  "医药":'PHARMACEUTICAL',
  "知识产权":"INTELLECTUAL PROPERTY",
  "教育":"EDUCATION",
  "新能源":"NEW ENERGY",
  "项目":'Project',
  "请选择想咨询的项目":"Please select the project you want to consult",
  "其他":"Other",
  "零售":"RETAIL",
  "物流":"LOGISTICS",
  "科创":"INNOVATION",
  "医药":"PHARMACEUTICAL",
  "金融":"FINANCE",
  "制造":'MANUFACTURING',
  "姓名":"Name",
  "请输入您的姓名":"Please enter your name",
  "公司":"Company",
  "请输入您的公司":"Please enter your company",
  "职位":"Position",
  "请输入您的职位":"Please enter your position",
  "联系电话":"Contact Number",
  "请输入您的联系电话":"Please enter your contact number",
  "公司邮箱":"Company Email",
  "请输入您的公司邮箱":"Please enter your company email",
  "描述":"Description",
  "请简单描述您的需求(选填)":"Please briefly describe your requirements (optional)",
  "请输入验证码":"Please enter the verification code",
  "提交":"Submit",
  "换一张":"Change another one",
}