<template>
  <!--  官网底部  -->

  <div class="footTotal">
    <!--        <div class="line"></div>-->
    <div class="bg" :style="getBjtp1('GwDiye.jpg')">
      <div class="bluext"></div>
      <div class="dibu">
        <div class="li1">
          <span class="li-item">{{ $t("latest_projects") }}</span>
          <br /><br />
          <!--                    <a href="http://www.pharmaxglobal.com.cn/index94.html">-->

          <span>
            <a href="http://www.logtalk.huiyuexpo.com/#/">
              {{ $t("latest2") }}
            </a>
          </span>
          <br /><br />
          <span>
            <a href="http://www.insight.huiyuexpo.com/">
              {{ $t("latest5") }}
            </a>
          </span>
          <br /><br />
          <span>
            <a href="">{{
              $t("latest3")
            }}</a>
          </span>
          <!-- <br /><br />
          <span>
            <a href="http://www.itall.tech/#/">{{ $t("latest3") }}</a>
          </span>
          <br /><br /> -->
          <!-- <span>
            <a href="http://www.pharmaxglobal.com.cn/#/">
              {{ $t("latest4") }}
            </a>
          </span> -->
          <!--                    </a>-->
          <!-- <br /><br />
          <span>
            <a href="http://www.insight.huiyuexpo.com/">
              {{ $t("latest5") }}
            </a>
          </span>
          <br /><br /> -->
        </div>
        <div class="li2">
          <span class="li-item">{{ $t("about_us") }}</span>
          <br /><br />
          <span style="cursor: pointer" @click="intro">{{
            $t("Company_Introduction")
          }}</span>
          <br /><br />
        </div>
        <div class="li3">
          <span class="li-item">{{ $t("Core_business") }}</span>
          <br /><br />
          <span>{{ $t("business1") }}</span>
          <br /><br />
          <span>{{ $t("business2") }}</span>
          <br /><br />
          <span>{{ $t("business3") }}</span>
          <br /><br />
          <!-- <span>{{ $t("business4") }}</span> -->
        </div>
        <div class="li4">
          <span class="li-item">{{ $t("Brand_Events") }}</span>
          <br /><br />
          <span>{{ $t("events1") }}</span>
          <br /><br />
          <!-- <span>{{ $t("events2") }}</span>
          <br /><br />
          <span>{{ $t("events3") }}</span>
          <br /><br /> -->
          <span>{{ $t("events4") }}</span>
          <br /><br />
          <span>{{ $t("events5") }}</span>
        </div>
        <div class="li5">
          <br /><br />
          <span>{{ $t("events6") }}</span>
          <br /><br />
          <span>{{ $t("events7") }}</span>
          <br /><br />
          <!-- <span>{{ $t("events8") }}</span>
          <br /><br /> -->
          <span>{{ $t("events9") }}</span>
          <!-- <br /><br /> -->
          <!-- <span>{{ $t("events10") }}</span> -->
        </div>
        <div class="li6">
          <br /><br />
          <!-- <span>{{ $t("events11") }}</span>
          <br /><br /> -->
          <span>{{ $t("events12") }}</span>
          <br /><br />
          <!-- <span>{{ $t("events13") }}</span>
          <br /><br /> -->
          <span>{{ $t("events14") }}</span>
          <br /><br />
          <span>{{ $t("events15") }}</span>
        </div>
        <div class="line2"></div>
        <div class="li6">
          <span class="contact-us">{{ $t("contact_us") }}</span>
          <br /><br />
          <span
            >{{ $t("addr1") }}<br />
            {{ $t("addr2") }}
          </span>
          <br /><br />
          <div class="huiYUicon">
            <img :src="fileServerUrl + 'assets/11back.png'" />
          </div>
          <!--                    <span class="littl-font">会/誉/会/展</span>-->
          <div class="foot-pic xfxs">
            <div class="phone pointer">
              <el-popover
                placement="bottom"
                title="+86 21 6125 8698"
                width="80"
                trigger="hover"
                v-model="visible1"
              >
                <i slot="reference">
                  <img
                    :src="fileServerUrl + 'assets/phone-fill-01.png'"
                    alt="phone"
                  />
                </i>
              </el-popover>
            </div>
            <div class="mail pointer">
              <el-popover
                placement="bottom"
                title="mkt@huiyuexpo.com"
                width="150"
                trigger="hover"
                v-model="visible2"
              >
                <i slot="reference">
                  <img
                    :src="fileServerUrl + 'assets/mail-fill-01.png'"
                    alt="mail"
                  />
                </i>
              </el-popover>
            </div>
            <div class="wechat pointer">
              <img
                :src="fileServerUrl + 'assets/wechat-fill-01.png'"
                alt="wechat"
              />

              <div class="display-xs">
                <img
                  :src="fileServerUrl + 'assets/gsErWei.jpg'"
                  style="width: 100px; height: 100px"
                  alt="wechat"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-last">
        <div class="fl-child">
          <div>
            © 2023 HUIYU EXHIBITION (SHANGHAI) CO.,LTD.会誉会展（上海）有限公司
          </div>
          <div>
            <a style="color: white" href="https://beian.miit.gov.cn/"
              >沪ICP备 2020038301号-1</a
            >
          </div>
          <div style="display: flex">
            <img
              :src="fileServerUrl + 'assets/beian.png'"
              style="width: 18px; height: 18px; margin-top: 4px"
            />
            <a
              style="color: white"
              href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010702008071"
              >沪公网安备 31010702008071号</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible1: false,
      visible2: false,
      fileServerUrl: fileServerUrl,
    };
  },
  methods: {
    intro() {
      document.querySelector("#a3").scrollIntoView(true);
    },
    getBjtp1(name) {
      let ys = {
        backgroundImage: "url(" + this.fileServerUrl + "assets/" + name + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "auto",
        // backgroundColor:'#524AF6FF',
        backgroundPosition: "center",
      };
      return ys;
    },
  },
};
</script>

<style lang="scss" scoped>
/*.line{*/
/*    height: 2px;*/
/*    margin-top: 40px;*/
/*    border-bottom: 3px solid #0238c0;*/
/*    width: 100%;*/
/*}*/
.footTotal {
  font-family: weiruan;
  color: white;
}
.bg {
  /*background-image: url("../assets/GwDiye.jpg");*/
  background-size: 100% 100%;
}

.dibu {
  //border: 0.1px solid red;
  padding-top: 12px;
  margin-top: 45px;
  font-size: 0.08rem;
  font-family: PuHuiTi;
  display: flex;
  margin-left: 80px;
  /*margin-bottom: 20px;*/

  span {
    a {
      color: #fff;
    }
  }
}
.li1 {
  margin-left: 12px;

  a:hover {
    border-bottom: 0.1px solid #fff;
  }
}
.li2 {
  margin-left: 35px;
}
.li3 {
  margin-left: 35px;
}
.li4 {
  margin-left: 35px;
}
.li5 {
  margin-left: 35px;
}
.li6 {
  margin-left: 35px;
}
.li-item {
  font-size: 9px;
  font-family: weiruan;
}
.contact-us {
  font-size: 9px;
  padding-left: 16px;
}
.huiYUicon {
  width: 45px;
  height: 15px;
  display: flex;
  margin-left: 10px;
}
.huiYUicon img {
  width: 45px;
  height: 15px;
}
.littl-font {
  padding-left: 18px;
  font-size: 5px;
}
.line2 {
  margin-left: 15px;
  border-right: 0.7px solid white;
  height: 155px;
}
.foot-pic {
  display: flex;

  padding-left: 6px;
  margin-top: 7px;
}
.foot-pic img {
  width: 10px;
  height: 10px;
}
.mail {
  margin-left: 12px;
}
.wechat {
  margin-left: 12px;
}
.footer-last {
  font-family: PuHuiTi;
  background-color: #0232a8;
  width: 100%;
  height: 30px;
  font-size: 0.08rem;
  margin-top: 10px;
  /*opacity: 10%;*/
  background-color: rgba(0, 0, 0, 0.1);
}
.fl-child {
  //border: 0.1px solid red;
  display: flex;
  justify-content: space-around;
  padding-top: 10px;
  margin-left: 14%;
  width: 500px;
}

.xfxs {
  position: relative;
}

.wechat:hover .display-xs {
  display: block;
}

.display-xs {
  width: 100px;
  height: 100px;
  position: absolute;
  left: 50%;
  display: none;
}
.pointer {
  cursor: pointer;
}
</style>
